import { getFiltersData, SearchConsumer, SortOrderPickerProvider } from '@deity/falcon-front-kit';
import { I18n } from '@deity/falcon-i18n';
import { Box, FlexLayout } from '@deity/falcon-ui';
import { CategoryArea, Loader } from '@deity/falcon-ui-kit';
import { NetworkStatus } from 'apollo-client';
import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from '../../elements/Breadcrumbs/Breadcrumbs';
import { Filters } from '../../elements/Filters/Filters';
import { FiltersSummary } from '../../elements/Filters/FiltersSummary';
import { ProductList } from '../../elements/ProductList/ProductList';
import { SearchQuery } from '../../elements/Search/SearchQuery';
import { UPDATE_SEARCH_QUERY } from '../../elements/Search/UpdateSearchQuery';
import { SortOrder } from '../../elements/SortOrder/SortOrder';
import Container from '../../layout/Container/Container';
import { CustomerQuery, GET_CUSTOMER_GROUP_QUERY } from '../../overrides/Customer/CustomerQuery';
import { Multiselect } from '../../ui/Multiselect/Multiselect';
import { ShowMore } from '../../ui/ShowMore/ShowMore';
import { InternalSearchTracking, ItemListTracking, SearchTracking } from '../../elements/Tracking/GoogleTagManager';
import { useStatelessQuery } from '../../hooks';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { BodyElementAttribute } from '../../helpers/BodyElementAttribute/BodyElementAttribute';
import { SearchNoResults } from './SearchNoResults';
import styles from './Styles.module.scss';

const copy = item => item && JSON.parse(JSON.stringify(item));

const getDefaultSortBy = (availableSortBy, defaultSortBy) =>
  availableSortBy.find(sortby => sortby === defaultSortBy) || (availableSortBy.length && availableSortBy[0]) || '';

const AVAILABLE_SORT_BY = [
  'position:asc',
  'name:asc',
  'name:desc',
  'price:asc',
  'price:desc',
  'popularity:desc',
  'rating:desc'
];

const DEFAULT_SORT_BY = 'popularity:desc';

const SearchPage = withStoreConfiguration(({ storeConfiguration, history }) => {
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [lastQueryUpdated, setLastQueryUpdated] = useState();
  const statelessQuery = useStatelessQuery();
  const [availableSortBy, setAvailableSortBy] = useState(AVAILABLE_SORT_BY);
  const [defaultSortBy, setDefaultSortBy] = useState(getDefaultSortBy(availableSortBy, DEFAULT_SORT_BY));

  useEffect(() => {
    const configSortby = storeConfiguration.search.sortby;
    setAvailableSortBy(
      (configSortby.available && configSortby.available.split(',').map(sortby => sortby.trim())) || AVAILABLE_SORT_BY
    );
    setDefaultSortBy(getDefaultSortBy(availableSortBy, configSortby.default || DEFAULT_SORT_BY));
  }, [storeConfiguration?.search?.sortby]);

  useEffect(() => {
    BodyElementAttribute({
      key: 'data-page-type',
      value: 'search',
      action: 'set'
    });

    return () => {
      BodyElementAttribute({
        key: 'data-page-type',
        action: 'remove'
      });
    };
  }, []);

  return (
    <React.Fragment>
      <Container>
        {loaderStatus ? (
          <div className="PageLoaderContainer">
            <div className={['PageLoader', 'plpLoaderDesktop', 'HideMobile', 'HideTablet'].join(' ')} />
            <div className={['PageLoader', 'plpLoaderTablet', 'HideMobile', 'ShowTablet'].join(' ')} />
            <div className={['PageLoader', 'plpLoaderMobile', 'ShowMobile'].join(' ')} />
          </div>
        ) : (
          <Breadcrumbs breadcrumbs={[]} />
        )}
        <SearchConsumer>
          {({ state }) => (
            <CustomerQuery query={GET_CUSTOMER_GROUP_QUERY}>
              {({ data: { customer } }) => (
                <SearchQuery
                  variables={{
                    term: state.term || '',
                    customerGroupId: (customer && customer.groupId) || 0,
                    sort: state.sort,
                    filters: copy(state.filters)
                  }}
                  passLoading
                  passError
                >
                  {({ data: { search }, fetchMore, networkStatus, loading, error }) => {
                    if (loading) {
                      if (!search) {
                        return <Loader />;
                      }
                    }

                    if (error) {
                      console.log(`error in search for term: ${error}`);
                      search = {};
                    }

                    setLoaderStatus(false);
                    const { query, productList } = search || {};
                    const { pagination = { totalItems: 0 }, items, aggregations, redirectUrl, unfilteredCount } =
                      productList || {};
                    const filtersData = getFiltersData(state.filters, aggregations);

                    if (!loading && query && query !== lastQueryUpdated && unfilteredCount >= 0) {
                      setLastQueryUpdated(query);
                      statelessQuery(
                        UPDATE_SEARCH_QUERY,
                        { queryText: query, numResults: unfilteredCount },
                        { fetchPolicy: 'network-only' }
                      ).then(() => {
                        SearchTracking({ storeConfiguration, products: items });
                        InternalSearchTracking({
                          searchTerm: query,
                          storeConfiguration,
                          searchResults: pagination?.totalItems,
                          bufferSeconds: 1
                        });
                      });
                    }

                    if (redirectUrl && redirectUrl !== 'false') {
                      history.replace(redirectUrl);
                    }
                    if (!loading && (!items || !items.length) && (!filtersData || !filtersData.length)) {
                      return <SearchNoResults term={state.term} />;
                    }

                    return (
                      <React.Fragment>
                        <div className={styles.Search}>
                          {loading && <Loader variant="overlay" />}
                          <h1 className={[styles.SearchName, 'ShowTablet'].join(' ')}>
                            &quot;{state.term}&quot;
                            <span> ({pagination.totalItems || 0})</span>
                          </h1>
                          <div className={styles.Filters}>
                            {!!filtersData.length && (
                              <Box gridArea={CategoryArea.filters}>
                                <Filters data={filtersData} />
                              </Box>
                            )}
                          </div>
                          <I18n>
                            {t => (
                              <SortOrderPickerProvider>
                                {sortOrdersProps => {
                                  const content = (
                                    <SortOrder
                                      availableSortBy={availableSortBy}
                                      defaultSortBy={defaultSortBy}
                                      {...sortOrdersProps}
                                      optionList
                                    />
                                  );
                                  return (
                                    <div className="ShowTablet">
                                      <Multiselect
                                        removePaddingOnMobile
                                        iconVariant="secondary"
                                        headers={[t('productList.sort.title')]}
                                        contents={[content]}
                                      />
                                    </div>
                                  );
                                }}
                              </SortOrderPickerProvider>
                            )}
                          </I18n>

                          <div className={styles.SearchProductList}>
                            <FlexLayout
                              justifyContent="space-between"
                              alignItems="center"
                              className={[styles.SearchProductListTop, 'HideTablet'].join(' ')}
                            >
                              <h1 className={styles.SearchName}>
                                &quot;{state.term}&quot;
                                <span> ({pagination.totalItems || 0})</span>
                              </h1>
                              {!loading && !items.length ? null : (
                                <SortOrderPickerProvider>
                                  {sortOrdersProps => (
                                    <SortOrder
                                      availableSortBy={availableSortBy}
                                      defaultSortBy={defaultSortBy}
                                      {...sortOrdersProps}
                                    />
                                  )}
                                </SortOrderPickerProvider>
                              )}
                            </FlexLayout>
                            <FiltersSummary data={filtersData} />

                            {!loading && !items.length ? (
                              <SearchNoResults term={state.term} smallWidth filtersData={filtersData} />
                            ) : (
                              <ProductList products={items} source="SearchPage" />
                            )}

                            {pagination.nextPage && (
                              <ShowMore onClick={fetchMore} loading={networkStatus === NetworkStatus.fetchMore} />
                            )}
                          </div>
                        </div>
                        {state.term && !loading && items.length ? (
                          <ItemListTracking
                            storeConfiguration={storeConfiguration}
                            category={search}
                            currentPage={pagination.currentPage}
                            products={items.slice((pagination.currentPage - 1) * 21, items.length)}
                            location={state.term}
                            area="search_list"
                            isCategory
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  }}
                </SearchQuery>
              )}
            </CustomerQuery>
          )}
        </SearchConsumer>
      </Container>
    </React.Fragment>
  );
});

export default SearchPage;
