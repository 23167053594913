import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const UPDATE_SEARCH_QUERY = gql`
  query UpdateSearchQuery($queryText: String!, $numResults: Int!) {
    updateSearchQuery(queryText: $queryText, numResults: $numResults)
  }
`;

export class UpdateSearchQuery extends Query {
  static defaultProps = {
    query: UPDATE_SEARCH_QUERY,
    fetchPolicy: 'network-only'
  };
}
