import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_PRODUCT_LIST = gql`
  query SearchProducts(
    $term: String!
    $customerGroupId: Int
    $sort: SortOrderInput
    $pagination: PaginationInput
    $filters: [FilterInput!]
  ) {
    search: customerSearch(term: $term, customerGroupId: $customerGroupId) {
      query
      productList(input: { sort: $sort, pagination: $pagination, filters: $filters, preview: false }) {
        items {
          id
          name
          shortName
          sku
          type
          cosmeticlineValue
          categoryPath
          price {
            regular
            special
            minPrice
            maxPrice
            minTier
          }
          productImage {
            default
            retina
            defaultWebp
            retinaWebp
          }
          urlPath
          volume
          popularity
          rating
          reviewsSummary {
            totalCount
            averageRating
          }
          baseprice
          isInStock
          shortDescription
          brand
          options {
            id
            label
            values {
              valueIndex
              label
              isInStock
              swatch {
                type
                value
              }
            }
          }
          labels {
            entityId
            text
            type
            percent
            isSale
            size
            rotated
            transparent
            badge
            images {
              image
            }
          }
          stock {
            isInStock
            qty
          }
          hasCustomOptions
          applicationType
          categoryIds
          subscriptionOffer {
            enabled
          }
        }
        pagination {
          currentPage
          totalItems
          nextPage
        }
        aggregations {
          field
          type
          buckets {
            value
            range
            count
            title
          }
          title
        }
        redirectUrl
        unfilteredCount
      }
    }
  }
`;

const fetchMore = (data, apolloFetchMore) =>
  apolloFetchMore({
    variables: {
      pagination: {
        page: data.search.productList.pagination.nextPage
      }
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        return prev;
      }

      return {
        ...prev,
        search: {
          ...prev.search,
          productList: {
            ...prev.search.productList,
            items: [...prev.search.productList.items, ...fetchMoreResult.search.productList.items],
            pagination: { ...fetchMoreResult.search.productList.pagination }
          }
        }
      };
    }
  });

export class SearchQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCT_LIST,
    fetchMore,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  };
}
