import { UrlQuery } from '@deity/falcon-data';
import { I18n } from '@deity/falcon-i18n';
import React from 'react';
import { Loader } from '@deity/falcon-ui-kit';
import { CmsPageQuery } from '../../elements/Cms/CmsQuery';
import { CmsHeadline } from '../../elements/Cms/Modules/Components/CmsHeadline';
import { stripTags } from '../../helpers/Html/StripTags';
import loadable from '../../../../../components/loadable';
import { OdoscopeSlider } from '../../elements/Odoscope/Slider';
import styles from './Styles.module.scss';

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../elements/Cms/Row'));

const URL_QUERY_PATH_FULL = '/no-search-result';
const URL_QUERY_PATH_SMALL = '/no-search-result-filter';

export const SearchNoResults = ({ term, smallWidth }) => {
  const urlQueryPath = smallWidth ? URL_QUERY_PATH_SMALL : URL_QUERY_PATH_FULL;
  const parsedTerm = stripTags(term);
  let firstSlider = false;
  return (
    <UrlQuery variables={{ path: urlQueryPath }}>
      {data => {
        return (
          <I18n>
            {t => (
              <div className={styles.NoSearchResultContainer}>
                <CmsHeadline headline={t('search.noResults.title', { term: parsedTerm })} headlineTag="h1" />
                <CmsPageQuery variables={{ id: data.data.url.id }}>
                  {({ data: { contentfulPage }, loading }) => {
                    if (loading) {
                      return <Loader />;
                    }
                    return (
                      <>
                        <div className={styles.NoSearchResultContentContainer}>
                          {contentfulPage.contentData.modules.map((item, index) => {
                            if (item.sys.contentType.sys.id === 'element_product_filter' && !firstSlider) {
                              firstSlider = true;
                              return (
                                <>
                                  <div className={styles.ProductSliderWrapper} data-slider-source="odoscope">
                                    <OdoscopeSlider
                                      title={t('notFound.odoscopeSliderTitle')}
                                      location="not_found_page"
                                    />
                                  </div>
                                  <Row
                                    key={`Row -'${index}'`} // eslint-disable-line react/no-array-index-key
                                    item={item.fields}
                                    contentType={item.sys.contentType.sys.id}
                                  />
                                </>
                              );
                            }
                            return (
                              <Row
                                key={`Row -'${index}'`} // eslint-disable-line react/no-array-index-key
                                item={item.fields}
                                contentType={item.sys.contentType.sys.id}
                              />
                            );
                          })}
                        </div>
                        {!firstSlider ? (
                          <div className={styles.ProductSliderWrapper} data-slider-source="odoscope">
                            <OdoscopeSlider title={t('notFound.odoscopeSliderTitle')} location="not_found_page" />
                          </div>
                        ) : null}
                      </>
                    );
                  }}
                </CmsPageQuery>
              </div>
            )}
          </I18n>
        );
      }}
    </UrlQuery>
  );
};
